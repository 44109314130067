<template>
  <form @submit.prevent="getData">
    <div class="columns is-centered">
      <div class="column is-6-fullhd is-7-widescreen is-8-desktop is-11-tablet is-12-mobile">
        <!-- 管理番号 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">pin</span>管理番号</p>
          </header>
          <div class="card-content">
            <p class="has-text-weight-bold">管理番号および申込人情報を入力してデータを取得してください</p>
            <div class="p-3 mb-3">
              <label class="label">管理番号</label>
              <div class="columns mb-0">
                <div class="column is-5-tablet is-12-mobile">
                  <div class="field">
                    <div class="control">
                      <input class="input" type="text" v-model="form.tf_cnkyno" />
                    </div>
                  </div>
                </div>
              </div>
              <ErrorField :error="f$.tf_cnkyno.$errors" />

              <label class="label">氏名カナ（全角）</label>
              <p class="text-info mb-3">※姓名の間に全角スペースを入れてください</p>
              <div class="columns mb-0">
                <div class="column is-8-tablet is-12-mobile">
                  <div class="field">
                    <div class="control">
                      <input class="input" type="text" v-model="form.tf_cnmskn" @change="replaceSpace" />
                    </div>
                  </div>
                </div>
              </div>
              <ErrorField :error="f$.tf_cnmskn.$errors" />

              <label class="label">生年月日</label>
              <div class="columns mb-0">
                <SelectDate
                  :generalColumnClass="'is-3-tablet is-full-mobile pr-0'"
                  :baseYear="year"
                  :yearSelectableUpperLimit="-18"
                  :yearSelectableLowerLimit="-100"
                  v-model:yearValue="form.tf_cnmstlY"
                  v-model:monthValue="form.tf_cnmstlM"
                  v-model:dateValue="form.tf_cnmstlD"
                />
              </div>
              <ErrorField :error="f$.tf_cnmstlY.$errors" />
              <ErrorField :error="f$.tf_cnmstlM.$errors" />
              <ErrorField :error="f$.tf_cnmstlD.$errors" />

              <div class="columns is-centered" v-if="g$.length > 0">
                <div class="column is-12">
                  <article class="message is-danger">
                    <div class="message-body">
                      <p v-for="(v, i) in g$" :key="i">{{ v }}</p>
                    </div>
                  </article>
                </div>
              </div>

              <div class="columns mb-0">
                <div class="column is-6-tablet is-12-mobile">
                  <button @click.prevent="getData" class="button next-btn is-fullwidth mb-3">データ取得</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-area field">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-full-mobile">
              <div class="control">
                <router-link :to="{ name: 'DisclosureStatement' }" class="button prev-btn is-medium is-fullwidth">戻る</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { SCR030FormValidateRule } from "@/forms/scr030";
import { ControlNumberRequest, getRentalContract } from "@/api/controlNumber";
import { defineComponent, reactive, ref, UnwrapRef } from "vue";
import ErrorField from "common/src/components/ErrorField.vue";
import SelectDate from "common/src/components/SelectDate.vue";
import useVuelidate from "@vuelidate/core";
import axios from "axios";
import { setErrorMessage } from "common/src/utils/validate";
import { ErrorResponse } from "common/src/utils/request";
import { useStore } from "@/store";
import { EntryMutationTypes } from "@/store/modules/entry";
import router from "@/router";

export default defineComponent({
  components: {
    ErrorField,
    SelectDate,
  },
  setup() {
    const form: UnwrapRef<ControlNumberRequest> = reactive({
      tf_cnkyno: "",
      tf_cnmskn: "",
      tf_cnmstlY: undefined,
      tf_cnmstlM: undefined,
      tf_cnmstlD: undefined,
    });
    const store = useStore();
    if (store.state.entry.control.tfCnkyno !== "") {
      form.tf_cnkyno = store.state.entry.control.tfCnkyno;
      form.tf_cnmskn = store.state.entry.msk01p.tfMskyknSei + "　" + store.state.entry.msk01p.tfMskyknMei;
      form.tf_cnmstlY = store.state.entry.msk01p.tfMskybtY;
      form.tf_cnmstlM = store.state.entry.msk01p.tfMskybtM;
      form.tf_cnmstlD = store.state.entry.msk01p.tfMskybtD;
    }

    // 全体エラーメッセージ
    const g$: string[] = reactive([]);
    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(SCR030FormValidateRule, form, {
      $autoDirty: true,
      $externalResults,
    });

    const year = store.state.entry.msk01p.tfMsmsdtY;

    const getData = async () => {
      f$.value.$clearExternalResults();
      g$.splice(0, g$.length);
      f$.value.$touch();
      if (f$.value.$invalid) return;

      try {
        const res = await getRentalContract(form);
        // 取得したデータをストア内に保存
        store.commit(EntryMutationTypes.SET_CONTROL_NUMBER_DATA, res.data);

        // 申込が問題ないデータかチェック
        const serverDate = new Date(year, store.state.entry.msk01p.tfMsmsdtM - 1, store.state.entry.msk01p.tfMsmsdtD);
        const startDate = new Date(res.data.tf_cnhksk);
        const applicationStartDate = new Date(startDate);
        applicationStartDate.setMonth(startDate.getMonth() - 3); // 申込可能月に3カ月前を設定
        // 月を減算した結果の日付が存在しない場合
        if (applicationStartDate.getMonth() !== (startDate.getMonth() + 9) % 12) {
          applicationStartDate.setMonth(applicationStartDate.getMonth() - 1); // 申込可能月にさらに1ヶ月前を設定
        }
        applicationStartDate.setDate(1); // 月初に設定
        if (startDate < serverDate) {
          g$.push("申込受付期間外です。");
          g$.push("お手数ですが担当店にご連絡ください。");
        } else if (serverDate < applicationStartDate) {
          g$.push("申込受付期間外です。");
          g$.push("お手数ですが" + applicationStartDate.toLocaleDateString("ja-JP", dateFormat) + "以降に再度お手続きください。");
        } else {
          // SCR040 申込内容入力画面へ遷移
          router.push({ name: "ApplicationInput" });
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          setErrorMessage(g$, $externalResults, err.response?.data as ErrorResponse);
        }

        if (axios.isAxiosError(err) && err.response?.status === 404) {
          g$.push("賃貸契約情報が存在しません");
        }
      }
    };

    // 空白文字の置換処理
    const replaceSpace = () => {
      // 空白文字は全角に置換し統一させる
      form.tf_cnmskn = form.tf_cnmskn.replace(/ /g, "　");
    };

    // 日付フォーマット
    const dateFormat = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return {
      form,
      year,
      g$,
      f$,
      getData,
      replaceSpace,
      dateFormat,
    };
  },
});
</script>
