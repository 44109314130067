
import { createDefaultForm, SCR040Form, SCR040FormValidateRule } from "@/forms/scr040";
import useVuelidate from "@vuelidate/core";
import ErrorField from "common/src/components/ErrorField.vue";
import SelectDate from "common/src/components/SelectDate.vue";
import MainInsured from "@/components/MainInsured.vue";
import SubInsured from "@/components/SubInsured.vue";
import { defineComponent, onMounted, reactive, Ref, ref, UnwrapRef } from "vue";
import constants from "common/src/constants";
import { commaSplit } from "common/src/utils/number";
import { useStore } from "@/store";
import { EntryMutationTypes } from "@/store/modules/entry";
import router from "@/router";
import { StepMutationTypes } from "@/store/modules/steps";
import { scrollToError } from "common/src/utils/screen";
import { addressRequest, getAddress } from "common/src/api/address";
import { getSyoyuText, getYoutoText, getKozoText } from "common/src/utils/codes";
import { getAgents } from "common/src/api/agents";
import { setErrorMessage, createErrorObject, validDateUnderage } from "common/src/utils/validate";
import { ErrorsMutationTypes } from "@/store/modules/errors";

export default defineComponent({
  components: {
    ErrorField,
    SelectDate,
    MainInsured,
    SubInsured,
  },

  setup() {
    const store = useStore();
    const form: UnwrapRef<SCR040Form> = reactive(createDefaultForm());
    const mailAddress = store.state.mail.mailAddress;
    const controlNumberDisabled = store.state.entry.control.tfCnkyno !== "";

    // 被保険者情報の取得
    const insured1 = ref();
    const insured2 = ref();
    const insured3 = ref();
    const insured4 = ref();
    const insured5 = ref();
    const insured6 = ref();
    const insured7 = ref();
    const insured8 = ref();

    // 他保険種類管理配列
    const thMsthsrArr: Ref<string[]> = ref([]);
    if (form.msk01p.tfMsthsr.length > 0) {
      thMsthsrArr.value.push(...(form.msk01p.tfMsthsr.split("/") ?? []));
    }

    // 代表被保険者の固定値セット
    const mainInsured = form.msk02p[0];
    if (mainInsured.tfMhkysm === "") mainInsured.tfMhkysm = constants.HAIIIE.IIE;
    mainInsured.tfMhhkno = "001";
    mainInsured.enabled = true;
    // 物件(DB側では所有区分)・目的(DB側では用途)に関しては固定値をセット
    mainInsured.tfMhsykb = constants.SYOYU_KBN.JYUTAKU;
    mainInsured.tfMhytkb = constants.YOUTO.KAZAI;
    // 前データ残存時の対策
    // 法人の場合
    if (form.contract.tfCnkjhj === constants.KOJINHOJIN_KBN.HOJIN) {
      // 非表示中の目的の所在地と同じチェックボックスをクリアしておく
      form.msk01p.tfMslcsm = constants.HAIIIE.IIE;
      // 申込人の携帯電話番号をクリアしておく
      form.msk01p.tfMskyt2 = "";
      // 世帯主情報を活性にしておく
      mainInsured.tfMhkysm = constants.HAIIIE.IIE;
    }

    // 全体エラーメッセージ
    const g$: string[] = reactive([]);
    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(SCR040FormValidateRule, form, {
      $autoDirty: true,
      $externalResults,
    });
    // ストアのエラーを画面へ表示
    if (store.state.errors.errors !== undefined) {
      setErrorMessage(g$, $externalResults, store.state.errors.errors);
      store.commit(ErrorsMutationTypes.RESET_ERRORS);
      f$.value.$touch();
      scrollToError();
    }

    const getLocationAddress = async () => {
      if (form.location.tfMhhkyn.length === 7) {
        const req: addressRequest = { zipCode: form.location.tfMhhkyn };
        const res = (await getAddress(req)).data;
        form.location.tfMhhka1 = res.tf_ybthkj + res.tf_ybsckj + res.tf_ybcikj;
        form.location.tfMhhka3 = res.tf_ybthkn + res.tf_ybsckn + res.tf_ybcikn;
      }
    };

    const getContractorAddress = async () => {
      if (form.msk01p.tfMskyyn.length === 7) {
        const req: addressRequest = { zipCode: form.msk01p.tfMskyyn };
        try {
          const res = (await getAddress(req)).data;
          form.msk01p.tfMskya1 = res.tf_ybthkj + res.tf_ybsckj + res.tf_ybcikj;
          form.msk01p.tfMskya3 = res.tf_ybthkn + res.tf_ybsckn + res.tf_ybcikn;
        } catch (err) {
          return;
        }
      }
    };

    // 目的の所在地の建物名・部屋番号の桁数チェック
    const validBuilding = () => {
      f$.value.location.tfMhhka2Tatemono.$reset();
      f$.value.location.tfMhhka2HeyaBango.$reset();
      f$.value.location.tfMhhka2Tatemono.$touch();
      f$.value.location.tfMhhka2HeyaBango.$touch();
      if (form.location.tfMhhka2Tatemono.length + form.location.tfMhhka2HeyaBango.length > 99) {
        f$.value.location.tfMhhka2Tatemono.$errors.push(createErrorObject("建物名と部屋番号は合計99文字以内で入力してください"));
        f$.value.location.tfMhhka2HeyaBango.$errors.push(createErrorObject("建物名と部屋番号は合計99文字以内で入力してください"));
      }
    };

    // 契約者の住所チェック
    const validContractorAddress = () => {
      f$.value.msk01p.tfMskyyn.$reset();
      f$.value.msk01p.tfMskya1.$reset();
      f$.value.msk01p.tfMskya3.$reset();
      f$.value.msk01p.tfMskyyn.$touch();
      f$.value.msk01p.tfMskya1.$touch();
      f$.value.msk01p.tfMskya3.$touch();
      if (form.msk01p.tfMslcsm !== constants.HAIIIE.HAI) {
        if (form.msk01p.tfMskyyn === "") {
          f$.value.msk01p.tfMskyyn.$errors.push(createErrorObject("郵便番号を入力してください"));
        }
        if (form.msk01p.tfMskya1 === "") {
          f$.value.msk01p.tfMskya1.$errors.push(createErrorObject("住所 漢字を入力してください"));
        }
        if (form.msk01p.tfMskya3 === "") {
          f$.value.msk01p.tfMskya3.$errors.push(createErrorObject("住所 カナを入力してください"));
        }
      }
    };

    // 契約者の建物名・部屋番号の桁数チェック
    const validContractorBuilding = () => {
      f$.value.msk01p.tfMskya2Tatemono.$reset();
      f$.value.msk01p.tfMskya2HeyaBango.$reset();
      f$.value.msk01p.tfMskya2Tatemono.$touch();
      f$.value.msk01p.tfMskya2HeyaBango.$touch();
      if (form.msk01p.tfMskya2Tatemono.length + form.msk01p.tfMskya2HeyaBango.length > 99) {
        f$.value.msk01p.tfMskya2Tatemono.$errors.push(createErrorObject("建物名と部屋番号は合計99文字以内で入力してください"));
        f$.value.msk01p.tfMskya2HeyaBango.$errors.push(createErrorObject("建物名と部屋番号は合計99文字以内で入力してください"));
      }
    };

    // 契約者の生年月日 未成年チェック
    const validBirthDate = () => {
      f$.value.msk01p.tfMskybtY.$reset();
      f$.value.msk01p.tfMskybtY.$touch();
      if (form.msk01p.tfMskybtY !== undefined && form.msk01p.tfMskybtM !== undefined && form.msk01p.tfMskybtD !== undefined) {
        if (!validDateUnderage(form.msk01p.tfMskybtY, form.msk01p.tfMskybtM, form.msk01p.tfMskybtD)) {
          f$.value.msk01p.tfMskybtY.$errors.push(createErrorObject("未成年者は申込できません"));
        }
      }
    };

    // 電話番号のチェック
    const validTelNo = () => {
      f$.value.msk01p.tfMskytl.$reset();
      f$.value.msk01p.tfMskyt2.$reset();
      f$.value.msk01p.tfMskytl.$touch();
      f$.value.msk01p.tfMskyt2.$touch();
      if (form.msk01p.tfMskytl === "" && form.msk01p.tfMskyt2 === "") {
        f$.value.msk01p.tfMskytl.$errors.push(createErrorObject("いずれかの電話番号を入力してください"));
        f$.value.msk01p.tfMskyt2.$errors.push(createErrorObject("いずれかの電話番号を入力してください"));
      }
    };

    // 他保険会社名に関する検証処理
    const validOtherInsurance = () => {
      f$.value.msk01p.tfMsthcm.$reset();
      f$.value.msk01p.tfMsthcm.$touch();
      if (form.msk01p.tfMsthum === constants.UMU.ARI) {
        if (form.msk01p.tfMsthcm === "") {
          f$.value.msk01p.tfMsthcm.$errors.push(createErrorObject("会社名を入力してください"));
        }

        if ((form.msk01p.tfMsthcm?.length ?? 0) > 120) {
          f$.value.msk01p.tfMsthcm.$errors.push(createErrorObject("会社名は120文字以内で入力してください"));
        }
      }
    };

    // 現在年取得
    const year = store.state.entry.msk01p.tfMsmsdtY;

    // プラン部分のoption構築
    const feeOptions = store.state.plans.map((p) => ({ plan: p.tf_hrplan, text: `${commaSplit(p.tf_hrhrgk)}円` }));
    const houseOptions = store.state.plans.map((p) => ({
      plan: p.tf_hrplan,
      text: `保険金額：${commaSplit(p.tf_hrhkkg)}万円 | 保険料：${commaSplit(p.tf_hrhrhk)}円`,
    }));
    const liabilityOptions = store.state.plans.map((p) => ({
      plan: p.tf_hrplan,
      text: `保険金額：${commaSplit(p.tf_hrhtkg)}万円 | 保険料：${commaSplit(p.tf_hrhthk)}円`,
    }));

    // 代理店コードがセットされていない場合はセットを行う
    if (form.msk01p.tfMsdrcd === "") {
      getAgents().then((res) => {
        if (res.data.dtm01p.length > 0) {
          form.msk01p.tfMsdrcd = res.data.dtm01p[0].tf_drdrcd;
        }
      });
    }

    const validAndNext = () => {
      f$.value.$clearExternalResults();
      validBuilding();
      validContractorAddress();
      validContractorBuilding();
      validBirthDate();
      validTelNo();
      insured1.value.validBirthDate();
      insured2.value.validBirthDate();
      insured3.value.validBirthDate();
      insured4.value.validBirthDate();
      insured5.value.validBirthDate();
      insured6.value.validBirthDate();
      insured7.value.validBirthDate();
      insured8.value.validBirthDate();
      validOtherInsurance();
      f$.value.$touch();
      // 2回目のチェック以降最後に呼び出したメソッドのエラーがf$.value.$errorsに反映されないので、エラーメッセージ表示領域が存在するかもチェックする
      if (f$.value.$invalid || f$.value.$errors.length > 0 || document.getElementsByClassName("help is-danger").length > 0) {
        scrollToError();
        return;
      }

      // 他保険会社無し時は会社名・金額を送らない
      if (form.msk01p.tfMsthum === constants.UMU.NASI) {
        form.msk01p.tfMsthcm = "";
        form.msk01p.tfMsthsr = "";
      } else {
        form.msk01p.tfMsthsr = thMsthsrArr.value.join("/");
      }

      // 目的の所在地と同じチェック時は目的地の値をセット
      if (form.msk01p.tfMslcsm === constants.HAIIIE.HAI) {
        form.msk01p.tfMskyyn = form.location.tfMhhkyn;
        form.msk01p.tfMskya1 = form.location.tfMhhka1;
        form.msk01p.tfMskya2Tatemono = form.location.tfMhhka2Tatemono;
        form.msk01p.tfMskya2HeyaBango = form.location.tfMhhka2HeyaBango;
        form.msk01p.tfMskya3 = form.location.tfMhhka3;
        form.msk01p.tfMskya4 = form.location.tfMhhka4;
      }

      // 契約者と同一チェック時は契約者の値をセット
      copyContractEvent();

      // フォームデータをストア内に保存
      store.commit(EntryMutationTypes.SET_ENTRY, form);
      // ステップ4の検証をOKにする
      store.commit(StepMutationTypes.STEP4, true);
      // SCR040 申込内容確認画面へ遷移
      router.push({ name: "ConfirmInformation" });
    };

    // 契約者と同一チェック時は被保険者に契約者の値をセット
    const copyContractEvent = () => {
      if (mainInsured.tfMhkysm === constants.HAIIIE.HAI) {
        mainInsured.tfMhhkskSei = form.msk01p.tfMskyknSei;
        mainInsured.tfMhhkskMei = form.msk01p.tfMskyknMei;
        mainInsured.tfMhhksjSei = form.msk01p.tfMskykjSei;
        mainInsured.tfMhhksjMei = form.msk01p.tfMskykjMei;
        mainInsured.tfMhhkbtY = form.msk01p.tfMskybtY;
        mainInsured.tfMhhkbtM = form.msk01p.tfMskybtM;
        mainInsured.tfMhhkbtD = form.msk01p.tfMskybtD;
        mainInsured.tfMhhkt2 = form.msk01p.tfMskyt2;
        mainInsured.tfMhhktl = form.msk01p.tfMskytl;
        mainInsured.tfMhhkma = mailAddress;
      }
    };

    const isCorporateContentsVisible: Ref<boolean> = ref(form.contract.tfCnkjhj === constants.KOJINHOJIN_KBN.HOJIN ? true : false);
    const isIndividualContentsVisible: Ref<boolean> = ref(form.contract.tfCnkjhj === constants.KOJINHOJIN_KBN.KOJIN ? true : false);
    const isContractorSameAddressContentsVisible: Ref<boolean> = ref(form.contract.tfCnkjhj === constants.KOJINHOJIN_KBN.HOJIN ? true : false);
    const isInsuredNumberCheckContentsVisible: Ref<boolean> = ref(false);
    const isInsuredItemVisible: Array<Ref<boolean>> | boolean[] = Array(8).fill(true).map(() => ref(true));

    const switchContentsVisiblityEvent = () => {
      // 表示非表示の切替処理
      // 目的の所在地と同じチェック時
      isContractorSameAddressContentsVisible.value = form.msk01p.tfMslcsm === constants.HAIIIE.IIE ? true : false;
    };

    const changeNumberOfInsured = () => {
      // 被保険者数変更時の処理
      const startValue = form.insuredCount.numberOfInsured === "" ? 1 : Number(form.insuredCount.numberOfInsured);
      displayInsuredItems(startValue);
    };

    const displayInsuredItems = (startValue: number) => {
      // 各被保険者ごとの入力欄を全て表示かつ活性化
      for (let i = 1; i < 8; i++) {
        isInsuredItemVisible[i].value = true;
        form.msk02p[i].enabled = true;
      }
      // 各被保険者ごとの入力欄を被保険者数分まで非表示かつ非活性化
      for (let j = startValue; j < 8; j++) {
        isInsuredItemVisible[j].value = false;
        form.msk02p[j].enabled = false;
      }
    };

    // 画面ロード時の処理
    onMounted(() => {
      // 表示非表示の切替処理
      switchContentsVisiblityEvent();
      // 被保険者数変更時の処理
      changeNumberOfInsured();
    });

    return {
      form,
      year,
      mailAddress,
      getLocationAddress,
      getContractorAddress,
      validBuilding,
      validContractorBuilding,
      validTelNo,
      validOtherInsurance,
      validAndNext,
      mainInsured,
      insured1,
      insured2,
      insured3,
      insured4,
      insured5,
      insured6,
      insured7,
      insured8,
      thMsthsrArr,
      feeOptions,
      houseOptions,
      liabilityOptions,
      g$,
      f$,
      constants,
      getSyoyuText,
      getYoutoText,
      getKozoText,
      controlNumberDisabled,
      copyContractEvent,
      isCorporateContentsVisible,
      isIndividualContentsVisible,
      isContractorSameAddressContentsVisible,
      isInsuredNumberCheckContentsVisible,
      isInsuredItemVisible,
      switchContentsVisiblityEvent,
      changeNumberOfInsured,
      displayInsuredItems,
    };
  },
});
