import { Entry, Insured, Location, Contract, InsuredCount } from "@/api/entry";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import { computed } from "vue";
import { useStore } from "@/store";
import { validZenkakuKanaForAddress, validZenkakuKanaForName, validTel } from "common/src/utils/validate";

export type SCR040Form = {
  msk01p: Entry;
  msk02p: Insured[];
  location: Location;
  contract: Contract;
  insuredCount: InsuredCount;
};

export const createDefaultForm = (): SCR040Form => {
  const store = useStore();
  return {
    msk01p: {
      tfMshscd: store.state.entry.msk01p.tfMshscd,
      tfMshkpl: store.state.entry.msk01p.tfMshkpl,
      tfMsmsdtY: store.state.entry.msk01p.tfMsmsdtY,
      tfMsmsdtM: store.state.entry.msk01p.tfMsmsdtM,
      tfMsmsdtD: store.state.entry.msk01p.tfMsmsdtD,
      tfMskykjSei: store.state.entry.msk01p.tfMskykjSei,
      tfMskykjMei: store.state.entry.msk01p.tfMskykjMei,
      tfMskyknSei: store.state.entry.msk01p.tfMskyknSei,
      tfMskyknMei: store.state.entry.msk01p.tfMskyknMei,
      tfMskybtY: store.state.entry.msk01p.tfMskybtY,
      tfMskybtM: store.state.entry.msk01p.tfMskybtM,
      tfMskybtD: store.state.entry.msk01p.tfMskybtD,
      tfMsskdtY: store.state.entry.msk01p.tfMsskdtY,
      tfMsskdtM: store.state.entry.msk01p.tfMsskdtM,
      tfMsskdtD: store.state.entry.msk01p.tfMsskdtD,
      tfMslcsm: store.state.entry.msk01p.tfMslcsm,
      tfMskyyn: store.state.entry.msk01p.tfMskyyn,
      tfMskya1: store.state.entry.msk01p.tfMskya1,
      tfMskya2Tatemono: store.state.entry.msk01p.tfMskya2Tatemono,
      tfMskya2HeyaBango: store.state.entry.msk01p.tfMskya2HeyaBango,
      tfMskya3: store.state.entry.msk01p.tfMskya3,
      tfMskya4: store.state.entry.msk01p.tfMskya4,
      tfMskytl: store.state.entry.msk01p.tfMskytl,
      tfMskyt2: store.state.entry.msk01p.tfMskyt2,
      tfMsthum: store.state.entry.msk01p.tfMsthum,
      tfMsthcm: store.state.entry.msk01p.tfMsthcm,
      tfMsthsr: store.state.entry.msk01p.tfMsthsr,
      tfMsconv: store.state.entry.msk01p.tfMsconv,
      tfMshkhh: store.state.entry.msk01p.tfMshkhh,
      tfMsdrcd: store.state.entry.msk01p.tfMsdrcd,
      tfMsstcd: store.state.entry.msk01p.tfMsstcd,
      tfMsbscd: store.state.entry.msk01p.tfMsbscd,
    },
    msk02p: [...store.state.entry.msk02p, ...[...Array(8)].map(() => createDefaultInsured())].slice(0, 8),
    location: {
      tfMhhkyn: store.state.entry.location.tfMhhkyn,
      tfMhhka1: store.state.entry.location.tfMhhka1,
      tfMhhka2Tatemono: store.state.entry.location.tfMhhka2Tatemono,
      tfMhhka2HeyaBango: store.state.entry.location.tfMhhka2HeyaBango,
      tfMhhka3: store.state.entry.location.tfMhhka3,
      tfMhhka4: store.state.entry.location.tfMhhka4,
    },
    contract: {
      tfCnkjhj: store.state.entry.contract.tfCnkjhj,
    },
    insuredCount: {
      numberOfInsured: store.state.entry.insuredCount.numberOfInsured,
    },
  };
};

export const createDefaultInsured = (): Insured => ({
  enabled: false,
  tfMhhkno: "",
  tfMhsykb: "",
  tfMhytkb: "",
  tfMhkozo: "",
  tfMhkysm: "",
  tfMhhkskSei: "",
  tfMhhkskMei: "",
  tfMhhksjSei: "",
  tfMhhksjMei: "",
  tfMhhksx: "",
  tfMhhkbtY: undefined,
  tfMhhkbtM: undefined,
  tfMhhkbtD: undefined,
  tfMhhktl: "",
  tfMhhkt2: "",
  tfMhhkma: "",
});

export const SCR040FormValidateRule = computed(() => ({
  msk01p: {
    tfMshkpl: {
      required: helpers.withMessage("合計保険料を選択してください", required),
    },
    tfMskykjSei: {
      required: helpers.withMessage("氏名 漢字 姓を入力してください", required),
      max: helpers.withMessage(({ $params }) => `氏名 漢字 姓は${($params as { max: number }).max}文字以内で入力してください`, maxLength(50)),
    },
    tfMskykjMei: {
      required: helpers.withMessage("氏名 漢字 名を入力してください", required),
      max: helpers.withMessage(({ $params }) => `氏名 漢字 名は${($params as { max: number }).max}文字以内で入力してください`, maxLength(49)),
    },
    tfMskyknSei: {
      required: helpers.withMessage("氏名 カナ 姓を入力してください", required),
      max: helpers.withMessage(({ $params }) => `氏名 カナ 姓は${($params as { max: number }).max}文字以内で入力してください`, maxLength(50)),
      zenkakuKana: helpers.withMessage("氏名 カナ 姓は全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
    },
    tfMskyknMei: {
      required: helpers.withMessage("氏名 カナ 名を入力してください", required),
      max: helpers.withMessage(({ $params }) => `氏名 カナ 名は${($params as { max: number }).max}文字以内で入力してください`, maxLength(49)),
      zenkakuKana: helpers.withMessage("氏名 カナ 名は全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForName),
    },
    tfMskybtY: {
      required: helpers.withMessage("生年月日 年を選択してください", required),
    },
    tfMskybtM: {
      required: helpers.withMessage("生年月日 月を選択してください", required),
    },
    tfMskybtD: {
      required: helpers.withMessage("生年月日 日を選択してください", required),
    },
    tfMsskdtY: {
      required: helpers.withMessage("開始日 年を選択してください", required),
    },
    tfMsskdtM: {
      required: helpers.withMessage("開始日 月を選択してください", required),
    },
    tfMsskdtD: {
      required: helpers.withMessage("開始日 日を選択してください", required),
    },
    tfMshkhh: {
      required: helpers.withMessage("お支払い方法を選択してください", required),
    },
    tfMskyyn: {
      max: helpers.withMessage(({ $params }) => `郵便番号は${($params as { max: number }).max}文字で入力してください`, maxLength(7)),
      min: helpers.withMessage(({ $params }) => `郵便番号は${($params as { min: number }).min}文字で入力してください`, minLength(7)),
    },
    tfMskya1: {
      max: helpers.withMessage(({ $params }) => `住所 漢字は${($params as { max: number }).max}文字以内で入力してください`, maxLength(410)),
    },
    tfMskya2: {
      max: helpers.withMessage(({ $params }) => `建物名は${($params as { max: number }).max}文字以内で入力してください`, maxLength(100)),
    },
    tfMskya3: {
      max: helpers.withMessage(({ $params }) => `住所 カナは${($params as { max: number }).max}文字以内で入力してください`, maxLength(410)),
      zenkakuKana: helpers.withMessage("住所 カナは全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForAddress),
    },
    tfMskya4: {
      max: helpers.withMessage(({ $params }) => `建物名 カナは${($params as { max: number }).max}文字以内で入力してください`, maxLength(100)),
      zenkakuKana: helpers.withMessage("建物名 カナは全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForAddress),
    },
    // 中身がないと外部エラーもセットできないので必ずtrueを返すvalidatorをセット
    tfMskya2Tatemono: {
      any: () => true,
    },
    tfMskya2HeyaBango: {
      any: () => true,
    },
    tfMskytl: {
      max: helpers.withMessage(({ $params }) => `その他電話番号は${($params as { max: number }).max}文字以内で入力してください`, maxLength(13)),
      tel: helpers.withMessage("その他電話番号はハイフン付きで入力してください", validTel),
    },
    tfMskyt2: {
      max: helpers.withMessage(({ $params }) => `携帯電話番号は${($params as { max: number }).max}文字以内で入力してください`, maxLength(13)),
      tel: helpers.withMessage("携帯電話番号はハイフン付きで入力してください", validTel),
    },
    tfMsthcm: {
      any: () => true,
    },
    tfMsstcd: {
      required: helpers.withMessage("支店コードを入力してください", required),
      max: helpers.withMessage(({ $params }) => `支店コードは${($params as { max: number }).max}文字以内で入力してください`, maxLength(4)),
    },
    tfMsbscd: {
      required: helpers.withMessage("募集人コードを入力してください", required),
      max: helpers.withMessage(({ $params }) => `募集人コードは${($params as { max: number }).max}文字以内で入力してください`, maxLength(4)),
    },
  },
  location: {
    tfMhhkyn: {
      max: helpers.withMessage(({ $params }) => `郵便番号は${($params as { max: number }).max}文字で入力してください`, maxLength(7)),
      min: helpers.withMessage(({ $params }) => `郵便番号は${($params as { min: number }).min}文字で入力してください`, minLength(7)),
    },
    tfMhhka1: {
      required: helpers.withMessage("住所 漢字を入力してください", required),
      max: helpers.withMessage(({ $params }) => `住所 漢字は${($params as { max: number }).max}文字以内で入力してください`, maxLength(410)),
    },
    // 中身がないと外部エラーもセットできないので必ずtrueを返すvalidatorをセット
    tfMhhka2Tatemono: {
      any: () => true,
    },
    tfMhhka2HeyaBango: {
      any: () => true,
    },
    tfMhhka3: {
      required: helpers.withMessage("住所 カナを入力してください", required),
      max: helpers.withMessage(({ $params }) => `住所 カナは${($params as { max: number }).max}文字以内で入力してください`, maxLength(410)),
      zenkakuKana: helpers.withMessage("住所 カナは全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForAddress),
    },
    tfMhhka4: {
      max: helpers.withMessage(({ $params }) => `建物名 カナは${($params as { max: number }).max}文字以内で入力してください`, maxLength(100)),
      zenkakuKana: helpers.withMessage("建物名 カナは全角カナ・数字・スペース・英字・記号で入力してください", validZenkakuKanaForAddress),
    },
  },
  insuredCount: {
    // 中身がないと外部エラーもセットできないので必ずtrueを返すvalidatorをセット
    numberOfInsured: {
      required: helpers.withMessage("人数を入力してください", required),
    },
  },
}));
