<template>
  <!-- 本文領域 -->
  <form>
    <div class="columns is-centered" v-if="g$.length > 0">
      <div class="column is-6-fullhd is-7-widescreen is-8-desktop is-11-tablet is-12-mobile">
        <article class="message is-danger">
          <div class="message-body">
            <p v-for="(v, i) in g$" :key="i">{{ v }}</p>
          </div>
        </article>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-6-fullhd is-7-widescreen is-8-desktop is-11-tablet is-12-mobile">
        <!-- 目的の所在地 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">home</span>目的の所在地</p>
          </header>
          <div class="card-content location-info">
            <label class="label">郵便番号</label>
            <div class="columns">
              <div class="column is-5-tablet is-12-mobile">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model="form.location.tfMhhkyn"
                      :disabled="controlNumberDisabled"
                      @change="getLocationAddress"
                    />
                  </div>
                </div>
                <ErrorField :error="f$.location.tfMhhkyn.$errors" />
              </div>
            </div>

            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">住所<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
                  <div class="control">
                    <input class="input" type="text" v-model="form.location.tfMhhka1" :disabled="controlNumberDisabled" />
                  </div>
                </div>
                <ErrorField :error="f$.location.tfMhhka1.$errors" />
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">建物名</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      @input="validBuilding"
                      v-model="form.location.tfMhhka2Tatemono"
                      :disabled="controlNumberDisabled"
                    />
                  </div>
                </div>
                <ErrorField :error="f$.location.tfMhhka2Tatemono.$errors" />
              </div>
            </div>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">住所カナ<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
                  <div class="control">
                    <input class="input" type="text" v-model="form.location.tfMhhka3" :disabled="controlNumberDisabled" />
                  </div>
                </div>
                <ErrorField :error="f$.location.tfMhhka3.$errors" />
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">建物名カナ</label>
                  <div class="control">
                    <input class="input" type="text" v-model="form.location.tfMhhka4" :disabled="controlNumberDisabled" />
                  </div>
                </div>
                <ErrorField :error="f$.location.tfMhhka4.$errors" />
              </div>
            </div>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">部屋番号</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      @input="validBuilding"
                      v-model="form.location.tfMhhka2HeyaBango"
                      :disabled="controlNumberDisabled"
                    />
                  </div>
                </div>
                <ErrorField :error="f$.location.tfMhhka2HeyaBango.$errors" />
              </div>
            </div>
          </div>
        </div>

        <!-- 申込人（契約者） -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">person</span>申込人（契約者）</p>
          </header>
          <div class="card-content contractor-info">
            <label class="label">氏名 漢字</label>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">姓<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
                  <div class="control">
                    <input class="input" type="text" v-model="form.msk01p.tfMskykjSei" :disabled="controlNumberDisabled" />
                  </div>
                </div>
                <ErrorField :error="f$.msk01p.tfMskykjSei.$errors" />
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">名<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
                  <div class="control">
                    <input class="input" type="text" v-model="form.msk01p.tfMskykjMei" :disabled="controlNumberDisabled" />
                  </div>
                </div>
                <ErrorField :error="f$.msk01p.tfMskykjMei.$errors" />
              </div>
            </div>

            <label class="label">氏名 カナ</label>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">姓<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
                  <div class="control">
                    <input class="input" type="text" v-model="form.msk01p.tfMskyknSei" :disabled="controlNumberDisabled" />
                  </div>
                </div>
                <ErrorField :error="f$.msk01p.tfMskyknSei.$errors" />
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <label class="label">名<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
                  <div class="control">
                    <input class="input" type="text" v-model="form.msk01p.tfMskyknMei" :disabled="controlNumberDisabled" />
                  </div>
                </div>
                <ErrorField :error="f$.msk01p.tfMskyknMei.$errors" />
              </div>
            </div>

            <label class="label">生年月日<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="columns">
              <SelectDate
                :generalColumnClass="'is-3-tablet is-full-mobile pr-0'"
                :baseYear="year"
                :yearSelectableUpperLimit="-18"
                :yearSelectableLowerLimit="-100"
                :disabled="controlNumberDisabled"
                v-model:yearValue="form.msk01p.tfMskybtY"
                v-model:monthValue="form.msk01p.tfMskybtM"
                v-model:dateValue="form.msk01p.tfMskybtD"
              />
            </div>
            <ErrorField :error="f$.msk01p.tfMskybtY.$errors" />
            <ErrorField :error="f$.msk01p.tfMskybtM.$errors" />
            <ErrorField :error="f$.msk01p.tfMskybtD.$errors" />

            <div class="contractor-same-address-item">
              <div id="corporate-same-address-item" v-show="isCorporateContentsVisible">
                <label class="label border-contents-title" style="padding-left: 0; margin-left: 0;">住所
                  <span class="text-info has-text-danger-dark">（法人様や申込人様のご住所をご入力ください。）</span>
                </label>
              </div>
              <div id="individual-same-address-item" v-show="isIndividualContentsVisible">
                <label class="label border-contents-title" style="padding-left: 0; margin-left: 0;">住所</label>
                <input
                  id="contractor-same-address"
                  type="checkbox"
                  class="check-type-1 enable-check"
                  :true-value="constants.HAIIIE.HAI"
                  :false-value="constants.HAIIIE.IIE"
                  v-model="form.msk01p.tfMslcsm"
                  @change="switchContentsVisiblityEvent()"
                />
                <label for="contractor-same-address" class="label border-contents-title-right">
                  目的の所在地と同じ
                  <span class="text-info has-text-danger-dark">
                    （申込人と入居者が違う場合やセカンドハウスを借りられる場合のみチェックを外して入力してください。）
                  </span>
                </label>
              </div>

              <transition name="contents-transition">
                <div id="contractor-same-address-contents" class="contractor-same-address-contents border-contents" v-if="isContractorSameAddressContentsVisible">
                  <div class="columns">
                    <div class="column is-5-tablet is-12-mobile">
                      <div class="field">
                        <label class="label">郵便番号<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
                        <div class="control">
                          <input class="input" type="text" v-model="form.msk01p.tfMskyyn" @change="getContractorAddress" />
                        </div>
                      </div>
                      <ErrorField :error="f$.msk01p.tfMskyyn.$errors" />
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column is-half-tablet is-full-mobile">
                      <div class="field">
                        <label class="label">住所<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
                        <div class="control">
                          <input class="input" type="text" v-model="form.msk01p.tfMskya1" />
                        </div>
                      </div>
                      <ErrorField :error="f$.msk01p.tfMskya1.$errors" />
                    </div>
                    <div class="column is-half-tablet is-full-mobile">
                      <div class="field">
                        <label class="label">建物名</label>
                        <div class="control">
                          <input class="input" type="text" v-model="form.msk01p.tfMskya2Tatemono" @input="validContractorBuilding" />
                        </div>
                      </div>
                      <ErrorField :error="f$.msk01p.tfMskya2Tatemono.$errors" />
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-half-tablet is-full-mobile">
                      <div class="field">
                        <label class="label">住所カナ<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
                        <div class="control">
                          <input class="input" type="text" v-model="form.msk01p.tfMskya3" />
                        </div>
                      </div>
                      <ErrorField :error="f$.msk01p.tfMskya3.$errors" />
                    </div>
                    <div class="column is-half-tablet is-full-mobile">
                      <div class="field">
                        <label class="label">建物名カナ</label>
                        <div class="control">
                          <input class="input" type="text" v-model="form.msk01p.tfMskya4" />
                        </div>
                      </div>
                      <ErrorField :error="f$.msk01p.tfMskya4.$errors" />
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-half-tablet is-full-mobile">
                      <div class="field">
                        <label class="label">部屋番号</label>
                        <div class="control">
                          <input class="input" type="text" v-model="form.msk01p.tfMskya2HeyaBango" @input="validContractorBuilding" />
                        </div>
                      </div>
                      <ErrorField :error="f$.msk01p.tfMskya2HeyaBango.$errors" />
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <div id="individual-phone-number" v-show="isIndividualContentsVisible">
              <label class="label">
                電話番号
                <span class="tag is-danger is-light is-rounded ml-2">どちらか必須</span>
                <span class="text-info has-text-danger-dark ml-2">※ハイフン付きで入力してください</span>
              </label>
              <div class="columns">
                <div class="column is-half-tablet is-full-mobile">
                  <div class="field">
                    <label class="label">携帯電話番号</label>
                    <div class="control">
                      <input class="input" type="text" @input="validTelNo" @change="copyContractEvent()" v-model="form.msk01p.tfMskyt2" />
                    </div>
                  </div>
                  <ErrorField :error="f$.msk01p.tfMskyt2.$errors" />
                </div>
                <div class="column is-half-tablet is-full-mobile">
                  <div class="field">
                    <label class="label">その他電話番号</label>
                    <div class="control">
                      <input class="input" type="text" @input="validTelNo" @change="copyContractEvent()" v-model="form.msk01p.tfMskytl" />
                    </div>
                  </div>
                  <ErrorField :error="f$.msk01p.tfMskytl.$errors" />
                </div>
              </div>
            </div>

            <div id="corporate-phone-number" v-show="isCorporateContentsVisible">
              <label class="label">
                電話番号
                <span class="text-info has-text-danger-dark">※ハイフン付きで入力してください</span>
                <div><label class="text-info has-text-danger-dark">法人様の場合は火災保険の窓口となる部署等の電話番号をご入力ください</label></div>
              </label>
              <div class="columns">
                <div class="column is-half-tablet is-full-mobile">
                  <div class="field">
                    <div class="control">
                      <input class="input" type="text" @input="validTelNo" @change="copyContractEvent()" v-model="form.msk01p.tfMskytl" />
                    </div>
                  </div>
                  <ErrorField :error="f$.msk01p.tfMskytl.$errors" />
                </div>
              </div>
            </div>

            <label class="label">メールアドレス</label>
            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <div class="field">
                  <div class="control">
                    <input class="input" type="email" :value="mailAddress" disabled />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 被保険者 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">groups</span>被保険者（ご入居者全員）</p>
          </header>
          <div class="card-content insured-info">
            <p class="text-info">※新規契約の場合の被保険者は8人までとなります。</p>
            <p class="text-info has-text-danger-dark">※入居者全員を入力していただく必要があります。</p>

            <div class="column is-full-mobile" style="padding-left: 0;">
              <div class="field">
                <label class="label">
                  被保険者人数
                  <span class="tag is-danger is-light is-rounded ml-2">必須</span>
                  <span class="text-info has-text-danger-dark ml-2">※ご入居されるお客様の人数をご入力下さい。</span>
                </label>
                <div class="control">
                  <div class="select">
                    <select id="number-of-insured" @change="changeNumberOfInsured()" v-model="form.insuredCount.numberOfInsured">
                      <option value=""></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                  <span class="ml-2">人</span>
                </div>
                <ErrorField :error="f$.insuredCount.numberOfInsured.$errors" />
              </div>
            </div>

            <!-- 目的の所有者の代表者 -->
            <MainInsured
              ref="insured1"
              :baseYear="year"
              :is-individual-contents-visible="isIndividualContentsVisible"
              v-model:tfMhkysm="form.msk02p[0].tfMhkysm"
              v-model:tfMhhkskSei="form.msk02p[0].tfMhhkskSei"
              v-model:tfMhhkskMei="form.msk02p[0].tfMhhkskMei"
              v-model:tfMhhksjSei="form.msk02p[0].tfMhhksjSei"
              v-model:tfMhhksjMei="form.msk02p[0].tfMhhksjMei"
              v-model:tfMhhkbtY="form.msk02p[0].tfMhhkbtY"
              v-model:tfMhhkbtM="form.msk02p[0].tfMhhkbtM"
              v-model:tfMhhkbtD="form.msk02p[0].tfMhhkbtD"
              v-model:tfMhhksx="form.msk02p[0].tfMhhksx"
              v-model:tfMhhkt2="form.msk02p[0].tfMhhkt2"
              v-model:tfMhhktl="form.msk02p[0].tfMhhktl"
              v-model:tfMhhkma="form.msk02p[0].tfMhhkma"
              @copy-contract-event="copyContractEvent()"
            />
            <!-- 被保険者２ -->
            <SubInsured
              ref="insured2"
              :is-insured-number-check-contents-visible="isInsuredNumberCheckContentsVisible"
              v-model:enabled="form.msk02p[1].enabled"
              v-model:tfMhhkskSei="form.msk02p[1].tfMhhkskSei"
              v-model:tfMhhkskMei="form.msk02p[1].tfMhhkskMei"
              v-model:tfMhhksjSei="form.msk02p[1].tfMhhksjSei"
              v-model:tfMhhksjMei="form.msk02p[1].tfMhhksjMei"
              v-model:tfMhhkbtY="form.msk02p[1].tfMhhkbtY"
              v-model:tfMhhkbtM="form.msk02p[1].tfMhhkbtM"
              v-model:tfMhhkbtD="form.msk02p[1].tfMhhkbtD"
              v-model:tfMhhksx="form.msk02p[1].tfMhhksx"
              v-show="isInsuredItemVisible[1].value"
              :insuredNumber="2"
              :baseYear="year"
            />
            <!-- 被保険者３ -->
            <SubInsured
              ref="insured3"
              :is-insured-number-check-contents-visible="isInsuredNumberCheckContentsVisible"
              v-model:enabled="form.msk02p[2].enabled"
              v-model:tfMhhkskSei="form.msk02p[2].tfMhhkskSei"
              v-model:tfMhhkskMei="form.msk02p[2].tfMhhkskMei"
              v-model:tfMhhksjSei="form.msk02p[2].tfMhhksjSei"
              v-model:tfMhhksjMei="form.msk02p[2].tfMhhksjMei"
              v-model:tfMhhkbtY="form.msk02p[2].tfMhhkbtY"
              v-model:tfMhhkbtM="form.msk02p[2].tfMhhkbtM"
              v-model:tfMhhkbtD="form.msk02p[2].tfMhhkbtD"
              v-model:tfMhhksx="form.msk02p[2].tfMhhksx"
              v-show="isInsuredItemVisible[2].value"
              :insuredNumber="3"
              :baseYear="year"
            />
            <!-- 被保険者４ -->
            <SubInsured
              ref="insured4"
              :is-insured-number-check-contents-visible="isInsuredNumberCheckContentsVisible"
              v-model:enabled="form.msk02p[3].enabled"
              v-model:tfMhhkskSei="form.msk02p[3].tfMhhkskSei"
              v-model:tfMhhkskMei="form.msk02p[3].tfMhhkskMei"
              v-model:tfMhhksjSei="form.msk02p[3].tfMhhksjSei"
              v-model:tfMhhksjMei="form.msk02p[3].tfMhhksjMei"
              v-model:tfMhhkbtY="form.msk02p[3].tfMhhkbtY"
              v-model:tfMhhkbtM="form.msk02p[3].tfMhhkbtM"
              v-model:tfMhhkbtD="form.msk02p[3].tfMhhkbtD"
              v-model:tfMhhksx="form.msk02p[3].tfMhhksx"
              v-show="isInsuredItemVisible[3].value"
              :insuredNumber="4"
              :baseYear="year"
            />
            <!-- 被保険者５ -->
            <SubInsured
              ref="insured5"
              :is-insured-number-check-contents-visible="isInsuredNumberCheckContentsVisible"
              v-model:enabled="form.msk02p[4].enabled"
              v-model:tfMhhkskSei="form.msk02p[4].tfMhhkskSei"
              v-model:tfMhhkskMei="form.msk02p[4].tfMhhkskMei"
              v-model:tfMhhksjSei="form.msk02p[4].tfMhhksjSei"
              v-model:tfMhhksjMei="form.msk02p[4].tfMhhksjMei"
              v-model:tfMhhkbtY="form.msk02p[4].tfMhhkbtY"
              v-model:tfMhhkbtM="form.msk02p[4].tfMhhkbtM"
              v-model:tfMhhkbtD="form.msk02p[4].tfMhhkbtD"
              v-model:tfMhhksx="form.msk02p[4].tfMhhksx"
              v-show="isInsuredItemVisible[4].value"
              :insuredNumber="5"
              :baseYear="year"
            />
            <!-- 被保険者６ -->
            <SubInsured
              ref="insured6"
              :is-insured-number-check-contents-visible="isInsuredNumberCheckContentsVisible"
              v-model:enabled="form.msk02p[5].enabled"
              v-model:tfMhhkskSei="form.msk02p[5].tfMhhkskSei"
              v-model:tfMhhkskMei="form.msk02p[5].tfMhhkskMei"
              v-model:tfMhhksjSei="form.msk02p[5].tfMhhksjSei"
              v-model:tfMhhksjMei="form.msk02p[5].tfMhhksjMei"
              v-model:tfMhhkbtY="form.msk02p[5].tfMhhkbtY"
              v-model:tfMhhkbtM="form.msk02p[5].tfMhhkbtM"
              v-model:tfMhhkbtD="form.msk02p[5].tfMhhkbtD"
              v-model:tfMhhksx="form.msk02p[5].tfMhhksx"
              v-show="isInsuredItemVisible[5].value"
              :insuredNumber="6"
              :baseYear="year"
            />
            <!-- 被保険者７ -->
            <SubInsured
              ref="insured7"
              :is-insured-number-check-contents-visible="isInsuredNumberCheckContentsVisible"
              v-model:enabled="form.msk02p[6].enabled"
              v-model:tfMhhkskSei="form.msk02p[6].tfMhhkskSei"
              v-model:tfMhhkskMei="form.msk02p[6].tfMhhkskMei"
              v-model:tfMhhksjSei="form.msk02p[6].tfMhhksjSei"
              v-model:tfMhhksjMei="form.msk02p[6].tfMhhksjMei"
              v-model:tfMhhkbtY="form.msk02p[6].tfMhhkbtY"
              v-model:tfMhhkbtM="form.msk02p[6].tfMhhkbtM"
              v-model:tfMhhkbtD="form.msk02p[6].tfMhhkbtD"
              v-model:tfMhhksx="form.msk02p[6].tfMhhksx"
              v-show="isInsuredItemVisible[6].value"
              :insuredNumber="7"
              :baseYear="year"
            />
            <!-- 被保険者８ -->
            <SubInsured
              ref="insured8"
              :is-insured-number-check-contents-visible="isInsuredNumberCheckContentsVisible"
              v-model:enabled="form.msk02p[7].enabled"
              v-model:tfMhhkskSei="form.msk02p[7].tfMhhkskSei"
              v-model:tfMhhkskMei="form.msk02p[7].tfMhhkskMei"
              v-model:tfMhhksjSei="form.msk02p[7].tfMhhksjSei"
              v-model:tfMhhksjMei="form.msk02p[7].tfMhhksjMei"
              v-model:tfMhhkbtY="form.msk02p[7].tfMhhkbtY"
              v-model:tfMhhkbtM="form.msk02p[7].tfMhhkbtM"
              v-model:tfMhhkbtD="form.msk02p[7].tfMhhkbtD"
              v-model:tfMhhksx="form.msk02p[7].tfMhhksx"
              v-show="isInsuredItemVisible[7].value"
              :insuredNumber="8"
              :baseYear="year"
            />
          </div>
        </div>

        <!-- 申込内容 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">article</span>申込内容</p>
          </header>
          <div class="card-content application-info">
            <label class="label">申込日</label>
            <div class="columns">
              <SelectDate
                :generalColumnClass="'is-3-tablet is-full-mobile pr-0'"
                :baseYear="year"
                :yearSelectableUpperLimit="1"
                :yearSelectableLowerLimit="0"
                v-model:yearValue="form.msk01p.tfMsmsdtY"
                v-model:monthValue="form.msk01p.tfMsmsdtM"
                v-model:dateValue="form.msk01p.tfMsmsdtD"
                :disabled="true"
              />
            </div>

            <label class="label">保険期間</label>
            <p class="text-info mb-3">※開始日の午前零時から2年後の応当日の前日24時までとなります。</p>
            <label class="label">開始日</label>
            <div class="columns">
              <SelectDate
                :generalColumnClass="'is-3-tablet is-full-mobile pr-0'"
                :baseYear="year"
                :yearSelectableUpperLimit="9"
                :yearSelectableLowerLimit="0"
                :yearValueAscSort="true"
                :disabled="controlNumberDisabled"
                v-model:yearValue="form.msk01p.tfMsskdtY"
                v-model:monthValue="form.msk01p.tfMsskdtM"
                v-model:dateValue="form.msk01p.tfMsskdtD"
              />
            </div>

            <div class="columns">
              <div class="column is-half-tablet is-full-mobile">
                <div class="columns is-flex">
                  <div class="column is-half-tablet">
                    <div class="field">
                      <label class="label">契約区分</label>
                      <div class="control">
                        <div class="select is-fullwidth">
                          <select id="application-contract-type" disabled>
                            <option value="新規" selected>新規</option>
                            <option value="更新">更新</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-half-tablet">
                    <div class="field">
                      <label class="label">建物</label>
                      <div class="control">
                        <div class="select is-fullwidth">
                          <select v-model="mainInsured.tfMhkozo" :disabled="controlNumberDisabled">
                            <option :value="constants.KOZO.MOKUZO">{{ getKozoText(constants.KOZO.MOKUZO) }}</option>
                            <option :value="constants.KOZO.HIMOKUZO">{{ getKozoText(constants.KOZO.HIMOKUZO) }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-half-tablet is-full-mobile">
                <div class="columns is-flex">
                  <div class="column is-half-tablet">
                    <div class="field">
                      <label class="label">物件</label>
                      <div class="control">
                        <div class="select is-fullwidth">
                          <select v-model="mainInsured.tfMhsykb" disabled>
                            <option :value="constants.SYOYU_KBN.JYUTAKU" selected>{{ getSyoyuText(constants.SYOYU_KBN.JYUTAKU) }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-half-tablet">
                    <div class="field">
                      <label class="label">目的</label>
                      <div class="control">
                        <div class="select is-fullwidth">
                          <select v-model="mainInsured.tfMhytkb" disabled>
                            <option :value="constants.YOUTO.KAZAI" selected>{{ getYoutoText(constants.YOUTO.KAZAI) }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <label class="label">お支払い方法</label>
            <p class="text-info mb-3">※お支払いは一括払のみとなります。</p>
            <div class="columns">
              <div class="column is-12">
                <div class="field">
                  <div class="control">
                    <input
                      type="radio"
                      id="payment-convenience"
                      class="radio-type-2"
                      v-model="form.msk01p.tfMshkhh"
                      :value="constants.HARAIKOMI_HOUHOU.CONVENI"
                      :disabled="controlNumberDisabled"
                    />
                    <label for="payment-convenience">コンビニ決済</label>
                  </div>
                </div>
                <div class="payment-logo">
                  <img src="images/logo-seven.svg" />
                  <img src="images/logo-familymart.svg" />
                  <img src="images/logo-lawson.svg" />
                </div>
                <p class="text-info">コンビニ店頭にてお支払いいただけます。</p>
              </div>
            </div>
            <div class="columns">
              <div class="column is-12">
                <div class="field">
                  <div class="control">
                    <input
                      type="radio"
                      id="payment-direct"
                      class="radio-type-2"
                      v-model="form.msk01p.tfMshkhh"
                      :value="constants.HARAIKOMI_HOUHOU.TYOKU"
                      :disabled="controlNumberDisabled"
                    />
                    <label for="payment-direct">現金支払</label>
                  </div>
                </div>
              </div>
            </div>
            <ErrorField :error="f$.msk01p.tfMshkhh.$errors" />
          </div>
        </div>

        <!-- 補償内容 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">savings</span>補償内容</p>
          </header>
          <div class="card-content compensation-info">
            <div class="columns">
              <div class="column is-8">
                <div class="field">
                  <label class="label">合計保険料</label>
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="form.msk01p.tfMshkpl" :disabled="controlNumberDisabled">
                        <option v-for="f in feeOptions" :key="f.plan" :value="f.plan">{{ f.text }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <ErrorField :error="f$.msk01p.tfMshkpl.$errors" />
              </div>
            </div>
            <div class="columns is-block">
              <div class="column is-12 pb-0">
                <label class="label">家財保険</label>
                <p class="text-info">※修理費用拡張担保特約および契約手続きに関する特約を含みます</p>
              </div>
              <div class="column is-8">
                <div class="field">
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="form.msk01p.tfMshkpl" disabled>
                        <option v-for="h in houseOptions" :key="h.plan" :value="h.plan">{{ h.text }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-block">
              <div class="column is-12 pb-0">
                <label class="label">賠償責任保険</label>
                <p class="text-info">※借家人賠償責任保険、個人賠償責任保険は合計で1,000万円が限度となります。</p>
              </div>
              <div class="column is-8">
                <div class="field">
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="form.msk01p.tfMshkpl" disabled>
                        <option v-for="l in liabilityOptions" :key="l.plan" :value="l.plan">{{ l.text }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- その他保険 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">more</span>その他保険</p>
          </header>
          <div class="card-content other-insurance-info">
            <p class="text-info mb-3">
              ※他の保険契約（保険の対象を同一とする家財保険や個人賠償責任保険）にご加入の場合は「他社の保険に加入している」にチェックをし、内容を入力してください。
            </p>
            <div class="other-insured-item">
              <input
                type="checkbox"
                id="other-insured-join"
                v-model="form.msk01p.tfMsthum"
                :true-value="constants.UMU.ARI"
                :false-value="constants.UMU.NASI"
                class="check-type-1"
                @click="validOtherInsurance"
              />
              <label for="other-insured-join" class="has-text-weight-bold has-text-dark">他社の保険に加入している</label>
              <div class="other-insured-join-info">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="field">
                      <div class="control">
                        <input
                          type="checkbox"
                          v-model="thMsthsrArr"
                          :value="constants.HOKEN_SYURUI.HOUSE"
                          id="other-insured-household"
                          class="check-type-1"
                        />
                        <label for="other-insured-household" class="has-text-dark mr-6">家財保険</label>
                        <input
                          type="checkbox"
                          v-model="thMsthsrArr"
                          :value="constants.HOKEN_SYURUI.LIABILITY"
                          id="other-insured-liability"
                          class="check-type-1"
                        />
                        <label for="other-insured-liability" class="has-text-dark">個人賠償責任保険</label>
                      </div>
                    </div>
                  </div>
                  <div class="column is-12">
                    <label class="label">保険会社名</label>
                    <div class="field">
                      <div class="control">
                        <input class="input" type="text" @change="validOtherInsurance" v-model="form.msk01p.tfMsthcm" />
                      </div>
                    </div>
                    <ErrorField :error="f$.msk01p.tfMsthcm.$errors" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 代理店・支店・募集人 -->
        <div class="card mb-5">
          <header class="card-header card-title">
            <p class="card-header-title is-size-4"><span class="material-icons accent-color">apartment</span>代理店・支店・募集人</p>
          </header>
          <div class="card-content location-info">
            <label class="label">代理店コード</label>
            <div class="columns">
              <div class="column is-5-tablet is-12-mobile">
                <div class="field">
                  <div class="control">
                    <input class="input" type="text" v-model="form.msk01p.tfMsdrcd" disabled />
                  </div>
                </div>
              </div>
            </div>

            <label class="label">支店コード<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="columns">
              <div class="column is-5-tablet is-12-mobile">
                <div class="field">
                  <div class="control">
                    <input class="input" type="text" v-model="form.msk01p.tfMsstcd" :disabled="controlNumberDisabled" />
                  </div>
                </div>
                <ErrorField :error="f$.msk01p.tfMsstcd.$errors" />
              </div>
            </div>

            <label class="label">募集人コード<span class="tag is-danger is-light is-rounded ml-2">必須</span></label>
            <div class="columns">
              <div class="column is-5-tablet is-12-mobile">
                <div class="field">
                  <div class="control">
                    <input class="input" type="text" v-model="form.msk01p.tfMsbscd" :disabled="controlNumberDisabled" />
                  </div>
                </div>
                <ErrorField :error="f$.msk01p.tfMsbscd.$errors" />
              </div>
            </div>
          </div>
        </div>

        <div class="btn-area field">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-full-mobile">
              <div class="control">
                <a class="button next-btn is-medium is-fullwidth" @click="validAndNext">確認画面へ進む</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { createDefaultForm, SCR040Form, SCR040FormValidateRule } from "@/forms/scr040";
import useVuelidate from "@vuelidate/core";
import ErrorField from "common/src/components/ErrorField.vue";
import SelectDate from "common/src/components/SelectDate.vue";
import MainInsured from "@/components/MainInsured.vue";
import SubInsured from "@/components/SubInsured.vue";
import { defineComponent, onMounted, reactive, Ref, ref, UnwrapRef } from "vue";
import constants from "common/src/constants";
import { commaSplit } from "common/src/utils/number";
import { useStore } from "@/store";
import { EntryMutationTypes } from "@/store/modules/entry";
import router from "@/router";
import { StepMutationTypes } from "@/store/modules/steps";
import { scrollToError } from "common/src/utils/screen";
import { addressRequest, getAddress } from "common/src/api/address";
import { getSyoyuText, getYoutoText, getKozoText } from "common/src/utils/codes";
import { getAgents } from "common/src/api/agents";
import { setErrorMessage, createErrorObject, validDateUnderage } from "common/src/utils/validate";
import { ErrorsMutationTypes } from "@/store/modules/errors";

export default defineComponent({
  components: {
    ErrorField,
    SelectDate,
    MainInsured,
    SubInsured,
  },

  setup() {
    const store = useStore();
    const form: UnwrapRef<SCR040Form> = reactive(createDefaultForm());
    const mailAddress = store.state.mail.mailAddress;
    const controlNumberDisabled = store.state.entry.control.tfCnkyno !== "";

    // 被保険者情報の取得
    const insured1 = ref();
    const insured2 = ref();
    const insured3 = ref();
    const insured4 = ref();
    const insured5 = ref();
    const insured6 = ref();
    const insured7 = ref();
    const insured8 = ref();

    // 他保険種類管理配列
    const thMsthsrArr: Ref<string[]> = ref([]);
    if (form.msk01p.tfMsthsr.length > 0) {
      thMsthsrArr.value.push(...(form.msk01p.tfMsthsr.split("/") ?? []));
    }

    // 代表被保険者の固定値セット
    const mainInsured = form.msk02p[0];
    if (mainInsured.tfMhkysm === "") mainInsured.tfMhkysm = constants.HAIIIE.IIE;
    mainInsured.tfMhhkno = "001";
    mainInsured.enabled = true;
    // 物件(DB側では所有区分)・目的(DB側では用途)に関しては固定値をセット
    mainInsured.tfMhsykb = constants.SYOYU_KBN.JYUTAKU;
    mainInsured.tfMhytkb = constants.YOUTO.KAZAI;
    // 前データ残存時の対策
    // 法人の場合
    if (form.contract.tfCnkjhj === constants.KOJINHOJIN_KBN.HOJIN) {
      // 非表示中の目的の所在地と同じチェックボックスをクリアしておく
      form.msk01p.tfMslcsm = constants.HAIIIE.IIE;
      // 申込人の携帯電話番号をクリアしておく
      form.msk01p.tfMskyt2 = "";
      // 世帯主情報を活性にしておく
      mainInsured.tfMhkysm = constants.HAIIIE.IIE;
    }

    // 全体エラーメッセージ
    const g$: string[] = reactive([]);
    // フィールドエラーを外部からセットする変数
    const $externalResults = ref({});
    // フィールドエラー設定初期化
    const f$ = useVuelidate(SCR040FormValidateRule, form, {
      $autoDirty: true,
      $externalResults,
    });
    // ストアのエラーを画面へ表示
    if (store.state.errors.errors !== undefined) {
      setErrorMessage(g$, $externalResults, store.state.errors.errors);
      store.commit(ErrorsMutationTypes.RESET_ERRORS);
      f$.value.$touch();
      scrollToError();
    }

    const getLocationAddress = async () => {
      if (form.location.tfMhhkyn.length === 7) {
        const req: addressRequest = { zipCode: form.location.tfMhhkyn };
        const res = (await getAddress(req)).data;
        form.location.tfMhhka1 = res.tf_ybthkj + res.tf_ybsckj + res.tf_ybcikj;
        form.location.tfMhhka3 = res.tf_ybthkn + res.tf_ybsckn + res.tf_ybcikn;
      }
    };

    const getContractorAddress = async () => {
      if (form.msk01p.tfMskyyn.length === 7) {
        const req: addressRequest = { zipCode: form.msk01p.tfMskyyn };
        try {
          const res = (await getAddress(req)).data;
          form.msk01p.tfMskya1 = res.tf_ybthkj + res.tf_ybsckj + res.tf_ybcikj;
          form.msk01p.tfMskya3 = res.tf_ybthkn + res.tf_ybsckn + res.tf_ybcikn;
        } catch (err) {
          return;
        }
      }
    };

    // 目的の所在地の建物名・部屋番号の桁数チェック
    const validBuilding = () => {
      f$.value.location.tfMhhka2Tatemono.$reset();
      f$.value.location.tfMhhka2HeyaBango.$reset();
      f$.value.location.tfMhhka2Tatemono.$touch();
      f$.value.location.tfMhhka2HeyaBango.$touch();
      if (form.location.tfMhhka2Tatemono.length + form.location.tfMhhka2HeyaBango.length > 99) {
        f$.value.location.tfMhhka2Tatemono.$errors.push(createErrorObject("建物名と部屋番号は合計99文字以内で入力してください"));
        f$.value.location.tfMhhka2HeyaBango.$errors.push(createErrorObject("建物名と部屋番号は合計99文字以内で入力してください"));
      }
    };

    // 契約者の住所チェック
    const validContractorAddress = () => {
      f$.value.msk01p.tfMskyyn.$reset();
      f$.value.msk01p.tfMskya1.$reset();
      f$.value.msk01p.tfMskya3.$reset();
      f$.value.msk01p.tfMskyyn.$touch();
      f$.value.msk01p.tfMskya1.$touch();
      f$.value.msk01p.tfMskya3.$touch();
      if (form.msk01p.tfMslcsm !== constants.HAIIIE.HAI) {
        if (form.msk01p.tfMskyyn === "") {
          f$.value.msk01p.tfMskyyn.$errors.push(createErrorObject("郵便番号を入力してください"));
        }
        if (form.msk01p.tfMskya1 === "") {
          f$.value.msk01p.tfMskya1.$errors.push(createErrorObject("住所 漢字を入力してください"));
        }
        if (form.msk01p.tfMskya3 === "") {
          f$.value.msk01p.tfMskya3.$errors.push(createErrorObject("住所 カナを入力してください"));
        }
      }
    };

    // 契約者の建物名・部屋番号の桁数チェック
    const validContractorBuilding = () => {
      f$.value.msk01p.tfMskya2Tatemono.$reset();
      f$.value.msk01p.tfMskya2HeyaBango.$reset();
      f$.value.msk01p.tfMskya2Tatemono.$touch();
      f$.value.msk01p.tfMskya2HeyaBango.$touch();
      if (form.msk01p.tfMskya2Tatemono.length + form.msk01p.tfMskya2HeyaBango.length > 99) {
        f$.value.msk01p.tfMskya2Tatemono.$errors.push(createErrorObject("建物名と部屋番号は合計99文字以内で入力してください"));
        f$.value.msk01p.tfMskya2HeyaBango.$errors.push(createErrorObject("建物名と部屋番号は合計99文字以内で入力してください"));
      }
    };

    // 契約者の生年月日 未成年チェック
    const validBirthDate = () => {
      f$.value.msk01p.tfMskybtY.$reset();
      f$.value.msk01p.tfMskybtY.$touch();
      if (form.msk01p.tfMskybtY !== undefined && form.msk01p.tfMskybtM !== undefined && form.msk01p.tfMskybtD !== undefined) {
        if (!validDateUnderage(form.msk01p.tfMskybtY, form.msk01p.tfMskybtM, form.msk01p.tfMskybtD)) {
          f$.value.msk01p.tfMskybtY.$errors.push(createErrorObject("未成年者は申込できません"));
        }
      }
    };

    // 電話番号のチェック
    const validTelNo = () => {
      f$.value.msk01p.tfMskytl.$reset();
      f$.value.msk01p.tfMskyt2.$reset();
      f$.value.msk01p.tfMskytl.$touch();
      f$.value.msk01p.tfMskyt2.$touch();
      if (form.msk01p.tfMskytl === "" && form.msk01p.tfMskyt2 === "") {
        f$.value.msk01p.tfMskytl.$errors.push(createErrorObject("いずれかの電話番号を入力してください"));
        f$.value.msk01p.tfMskyt2.$errors.push(createErrorObject("いずれかの電話番号を入力してください"));
      }
    };

    // 他保険会社名に関する検証処理
    const validOtherInsurance = () => {
      f$.value.msk01p.tfMsthcm.$reset();
      f$.value.msk01p.tfMsthcm.$touch();
      if (form.msk01p.tfMsthum === constants.UMU.ARI) {
        if (form.msk01p.tfMsthcm === "") {
          f$.value.msk01p.tfMsthcm.$errors.push(createErrorObject("会社名を入力してください"));
        }

        if ((form.msk01p.tfMsthcm?.length ?? 0) > 120) {
          f$.value.msk01p.tfMsthcm.$errors.push(createErrorObject("会社名は120文字以内で入力してください"));
        }
      }
    };

    // 現在年取得
    const year = store.state.entry.msk01p.tfMsmsdtY;

    // プラン部分のoption構築
    const feeOptions = store.state.plans.map((p) => ({ plan: p.tf_hrplan, text: `${commaSplit(p.tf_hrhrgk)}円` }));
    const houseOptions = store.state.plans.map((p) => ({
      plan: p.tf_hrplan,
      text: `保険金額：${commaSplit(p.tf_hrhkkg)}万円 | 保険料：${commaSplit(p.tf_hrhrhk)}円`,
    }));
    const liabilityOptions = store.state.plans.map((p) => ({
      plan: p.tf_hrplan,
      text: `保険金額：${commaSplit(p.tf_hrhtkg)}万円 | 保険料：${commaSplit(p.tf_hrhthk)}円`,
    }));

    // 代理店コードがセットされていない場合はセットを行う
    if (form.msk01p.tfMsdrcd === "") {
      getAgents().then((res) => {
        if (res.data.dtm01p.length > 0) {
          form.msk01p.tfMsdrcd = res.data.dtm01p[0].tf_drdrcd;
        }
      });
    }

    const validAndNext = () => {
      f$.value.$clearExternalResults();
      validBuilding();
      validContractorAddress();
      validContractorBuilding();
      validBirthDate();
      validTelNo();
      insured1.value.validBirthDate();
      insured2.value.validBirthDate();
      insured3.value.validBirthDate();
      insured4.value.validBirthDate();
      insured5.value.validBirthDate();
      insured6.value.validBirthDate();
      insured7.value.validBirthDate();
      insured8.value.validBirthDate();
      validOtherInsurance();
      f$.value.$touch();
      // 2回目のチェック以降最後に呼び出したメソッドのエラーがf$.value.$errorsに反映されないので、エラーメッセージ表示領域が存在するかもチェックする
      if (f$.value.$invalid || f$.value.$errors.length > 0 || document.getElementsByClassName("help is-danger").length > 0) {
        scrollToError();
        return;
      }

      // 他保険会社無し時は会社名・金額を送らない
      if (form.msk01p.tfMsthum === constants.UMU.NASI) {
        form.msk01p.tfMsthcm = "";
        form.msk01p.tfMsthsr = "";
      } else {
        form.msk01p.tfMsthsr = thMsthsrArr.value.join("/");
      }

      // 目的の所在地と同じチェック時は目的地の値をセット
      if (form.msk01p.tfMslcsm === constants.HAIIIE.HAI) {
        form.msk01p.tfMskyyn = form.location.tfMhhkyn;
        form.msk01p.tfMskya1 = form.location.tfMhhka1;
        form.msk01p.tfMskya2Tatemono = form.location.tfMhhka2Tatemono;
        form.msk01p.tfMskya2HeyaBango = form.location.tfMhhka2HeyaBango;
        form.msk01p.tfMskya3 = form.location.tfMhhka3;
        form.msk01p.tfMskya4 = form.location.tfMhhka4;
      }

      // 契約者と同一チェック時は契約者の値をセット
      copyContractEvent();

      // フォームデータをストア内に保存
      store.commit(EntryMutationTypes.SET_ENTRY, form);
      // ステップ4の検証をOKにする
      store.commit(StepMutationTypes.STEP4, true);
      // SCR040 申込内容確認画面へ遷移
      router.push({ name: "ConfirmInformation" });
    };

    // 契約者と同一チェック時は被保険者に契約者の値をセット
    const copyContractEvent = () => {
      if (mainInsured.tfMhkysm === constants.HAIIIE.HAI) {
        mainInsured.tfMhhkskSei = form.msk01p.tfMskyknSei;
        mainInsured.tfMhhkskMei = form.msk01p.tfMskyknMei;
        mainInsured.tfMhhksjSei = form.msk01p.tfMskykjSei;
        mainInsured.tfMhhksjMei = form.msk01p.tfMskykjMei;
        mainInsured.tfMhhkbtY = form.msk01p.tfMskybtY;
        mainInsured.tfMhhkbtM = form.msk01p.tfMskybtM;
        mainInsured.tfMhhkbtD = form.msk01p.tfMskybtD;
        mainInsured.tfMhhkt2 = form.msk01p.tfMskyt2;
        mainInsured.tfMhhktl = form.msk01p.tfMskytl;
        mainInsured.tfMhhkma = mailAddress;
      }
    };

    const isCorporateContentsVisible: Ref<boolean> = ref(form.contract.tfCnkjhj === constants.KOJINHOJIN_KBN.HOJIN ? true : false);
    const isIndividualContentsVisible: Ref<boolean> = ref(form.contract.tfCnkjhj === constants.KOJINHOJIN_KBN.KOJIN ? true : false);
    const isContractorSameAddressContentsVisible: Ref<boolean> = ref(form.contract.tfCnkjhj === constants.KOJINHOJIN_KBN.HOJIN ? true : false);
    const isInsuredNumberCheckContentsVisible: Ref<boolean> = ref(false);
    const isInsuredItemVisible: Array<Ref<boolean>> | boolean[] = Array(8).fill(true).map(() => ref(true));

    const switchContentsVisiblityEvent = () => {
      // 表示非表示の切替処理
      // 目的の所在地と同じチェック時
      isContractorSameAddressContentsVisible.value = form.msk01p.tfMslcsm === constants.HAIIIE.IIE ? true : false;
    };

    const changeNumberOfInsured = () => {
      // 被保険者数変更時の処理
      const startValue = form.insuredCount.numberOfInsured === "" ? 1 : Number(form.insuredCount.numberOfInsured);
      displayInsuredItems(startValue);
    };

    const displayInsuredItems = (startValue: number) => {
      // 各被保険者ごとの入力欄を全て表示かつ活性化
      for (let i = 1; i < 8; i++) {
        isInsuredItemVisible[i].value = true;
        form.msk02p[i].enabled = true;
      }
      // 各被保険者ごとの入力欄を被保険者数分まで非表示かつ非活性化
      for (let j = startValue; j < 8; j++) {
        isInsuredItemVisible[j].value = false;
        form.msk02p[j].enabled = false;
      }
    };

    // 画面ロード時の処理
    onMounted(() => {
      // 表示非表示の切替処理
      switchContentsVisiblityEvent();
      // 被保険者数変更時の処理
      changeNumberOfInsured();
    });

    return {
      form,
      year,
      mailAddress,
      getLocationAddress,
      getContractorAddress,
      validBuilding,
      validContractorBuilding,
      validTelNo,
      validOtherInsurance,
      validAndNext,
      mainInsured,
      insured1,
      insured2,
      insured3,
      insured4,
      insured5,
      insured6,
      insured7,
      insured8,
      thMsthsrArr,
      feeOptions,
      houseOptions,
      liabilityOptions,
      g$,
      f$,
      constants,
      getSyoyuText,
      getYoutoText,
      getKozoText,
      controlNumberDisabled,
      copyContractEvent,
      isCorporateContentsVisible,
      isIndividualContentsVisible,
      isContractorSameAddressContentsVisible,
      isInsuredNumberCheckContentsVisible,
      isInsuredItemVisible,
      switchContentsVisiblityEvent,
      changeNumberOfInsured,
      displayInsuredItems,
    };
  },
});
</script>
